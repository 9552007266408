.userAttribute {
    display: block;
    font-size: small;
}

.userAttributeName {
    font-weight: bold;
}

.reload {
    margin-left: 1100px;
}

.totalRecords {
    font-weight: bold;
}

.Transaction .input-group {
    width: 20%;
}

.Transaction .transactions-per-page {
    float: left;
    margin-right: 50px;
}

/* For some reason lost rounding after adding clear button, add manually as a workaround */
.Transaction #searchTerm {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.search-term-clear-btn {
    position: absolute;
    width: 10px;
    font-weight: 550;
    border: none;
    background-color: transparent;
    line-height: 35px;
    left: calc(100% - 30px);
    z-index: 99999;
}

.search-term-clear-btn:hover {
    color: blue;
}

