.App {
    margin-top: 50px;
}

.App .charcot-top-nav {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: steelblue !important;
}

.App .text-muted {
    color: white !important;
    font-weight: bold;
    margin-left: 10px;
}
