#root {
    background-color: white;
}

.Splash .links {
    font-size: 1.5rem;
    margin-left: 100px;
    margin-top: 50px !important;
}

.Splash .icon-container {
    margin-left: 50px;
    margin-top: 50px;
    float: left;
}

.Splash .icon {
    width: 300px;
    height: 200px;
    margin-left: 75px;
}

.Splash .icon-container2 {
    margin-left: 75px;
    margin-top: 50px;
}

.icon-container2 span {
    display: inline-block;
    padding-left: 40px;
    padding-right: 120px;
    height: 100px;
    font-size: .75rem;
}

.Splash .icon-container2 a {
    text-decoration: none;
    color: black;
}

.Splash .icon2 {
    width: 350px;
}

.ismms {
    height: 100px;
    width: 200px !important;
}

.us-dept-va-affairs {
    height: available;
    width: 200px !important;
}

