.ForgotPassword {
    width: 500px;
}

.ForgotPassword #forgot-password-btn {
    --bs-btn-bg: white;
    --bs-btn-color: steelblue;
    --bs-btn-hover-bg: steelblue;
    --bs-btn-hover-color: white;
}

.ForgotPassword #reset-password-btn {
    --bs-btn-bg: steelblue;
    --bs-btn-color: white;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-color: steelblue;
}
