.TransactionDetail #back-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 175px;
    border: 1px solid lightgray;
    background-color: transparent;
    color: #0d6efd;
    font-weight: normal;
}

.TransactionDetail .filler {
    width: 310px;
}

.TransactionDetail td:nth-child(2) {
    font-weight: bolder;
}
