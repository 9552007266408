.Search {
    margin-top: 10px;
    margin-left: 135px;
}

.Search .charcot-search-table {
   /*margin-left: -50px;*/
}

.Search .charcot-search-table, td {
    border: none !important;
    vertical-align: top;
}

/*.Search .charcot-search-table tbody tr td > div {*/
.Search .charcot-search-div {
    height: 200px;
    position: relative;
    overflow: hidden !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: white;
    margin-right: 50px;
    margin-bottom: 50px;
    font-size: x-small !important;
}

.Search .charcot-search-div-gradient::after {
    content: '';
    position: absolute;
    display: block;
    width: 600px;
    height: 30px;
    bottom: 0px;
    background-image: linear-gradient(to bottom, rgba(204,204,204,0), rgba(204,204,204,1));
}

.Search .charcot-search-expand-btn {
    position: absolute;
    bottom: 10px;
    left: 520px;
    z-index: 1000;
    --bs-btn-bg: steelblue;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-color: steelblue;
}

.Search .charcot-search-collapse-btn {
    position: absolute;
    bottom: 35px;
    left: 500px;
    --bs-btn-bg: steelblue;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-color: steelblue;
}

.Search .charcot-search-tooltip-btn {
    margin-top: -10px;
}
