.FilterComponent {
    margin-bottom: 10px;
}

.FilterComponent #clear-all-btn {
    background-color: steelblue;
    height: 32px;
    border-radius: 18px;
    font-size: 10px;
}

.FilterComponent .clear-predicate-btn {
    height: 32px;
    border-radius: 18px;
    font-size: 10px;
    margin-left: 5px !important;
}

.FilterComponent .remove {
    font-weight: bolder;
    line-height: 10px;
    font-size: 11px;
    color: red;
    margin-left: 4px;
    text-decoration: underline dotted;
}

.FilterComponent .category-logical-operator-toggle {
    font-weight: bolder;
    line-height: 10px;
    font-size: 11px;
    color: blue;
    margin-left: 4px;
    text-decoration: underline dotted;
}

.FilterComponent .clear-predicate-btn-color-or {
    background-color: #46d246;
}

.FilterComponent .clear-predicate-btn-color-and {
    background-color: #ffa500;
}
