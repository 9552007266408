.LeftNav .DimensionAccordion {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    overflow: scroll;
}

.LeftNav .charcot-accordion-item {
    width: 191px;
    font-size: 10px;
}

.LeftNav .charcot-accordion-item .mb-3 {
    margin-bottom: .1rem !important;
    font-size: 11px;
}

.LeftNav .accordion-button {
    font-size: 14px;
}
