@media all and (min-width: 480px) {
    .ProfileManagement {
        padding: 60px 0;
    }

    .ProfileManagement form {
        margin: 0 auto;
        max-width: 320px;
    }

    .ProfileManagement .changePassword {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ProfileManagement .cancel {
        margin-top: 10px;
        margin-left: 5px;
    }


    .ProfileManagement #signup-submit-btn {
        --bs-btn-bg: steelblue;
        --bs-btn-color: white;
        --bs-btn-hover-bg: white;
        --bs-btn-hover-color: steelblue;
    }
}
