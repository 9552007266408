.ChangePassword .cancel {
    margin-top: 10px;
    margin-left: 5px;
}

.ChangePassword #back-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 175px;
    border: 1px solid lightgray;
    background-color: transparent;
    color: #0d6efd;
    font-weight: normal;
}
